<template>
    <div v-if="$can('access','PdfCodeVersion1')">
        <v-btn
            small
            class="ml-2 lighten-2"
            v-if="updateMode"
            @click.stop="viewPdf()"
        >
            <v-icon small>far fa-file-pdf</v-icon>
            <span class="ml-1">{{ $t('message.pdf') }}</span>
        </v-btn>
        <template v-if="salesTypeId == 5">
            <v-btn
                :loading="loading.proformaInvoiceDualCurrency"
                small
                class="ml-3 lighten-2"
                v-if="updateMode && [1,2].includes(salesStatusId)"
                @click.stop="viewPdf('proforma')"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.proformaInvoice') + ' ' + contractCurrency + '-' + officeCurrency }}</span>
            </v-btn>
            <v-btn
                :loading="loading.proformaInvoice"
                small
                class="ml-3 lighten-2"
                v-if="updateMode && [1,2].includes(salesStatusId)"
                @click.stop="viewPdf('proforma')"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.proformaInvoice') + ' ' + officeCurrency }}</span>
            </v-btn>
        </template>
        <template v-else>
            <v-btn
                :loading="loading.proformaInvoice"
                small
                class="ml-3 lighten-2"
                v-if="updateMode && [1,2].includes(salesStatusId)"
                @click.stop="viewPdf('proforma')"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.proformaInvoice') }}</span>
            </v-btn>
        </template>
    </div>
    <div v-else>
        <v-btn
            :loading="loading.contract"
            small
            class="ml-2 lighten-2"
            v-if="updateMode"
            @click.stop="printContract()"
        >
            <v-icon small>far fa-file-pdf</v-icon>
            <span class="ml-1">{{ $t('message.pdf') }}</span>
        </v-btn>
        <template v-if="salesTypeId == 5">
            <v-btn
                :loading="loading.proformaInvoiceDualCurrency"
                small
                class="ml-3 lighten-2"
                v-if="updateMode && [1,2].includes(salesStatusId)"
                @click.stop="printProformaInvoiceDualCurrency()"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.proformaInvoice') + ' ' + contractCurrency + '-' + officeCurrency }}</span>
            </v-btn>
            <v-btn
                :loading="loading.proformaInvoice"
                small
                class="ml-3 lighten-2"
                v-if="updateMode && [1,2].includes(salesStatusId)"
                @click.stop="printProformaInvoice()"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.proformaInvoice') + ' ' + officeCurrency }}</span>
            </v-btn>
        </template>
        <template v-else>
            <v-btn
                :loading="loading.proformaInvoice"
                small
                class="ml-3 lighten-2"
                v-if="updateMode && [1,2].includes(salesStatusId)"
                @click.stop="printProformaInvoice()"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.proformaInvoice') }}</span>
            </v-btn>
        </template>
        <PrintContractBlob
            :contract-id="contractId"
            :key="updateContractKey"
            @pdf-uploading="contractUploading"
            @pdf-uploaded="contractUploaded"
            @pdf-not-uploaded="contractNotUploaded"
            v-if="hasContract"
        />
        <PrintContractProformaInvoiceBlobV3
            :contract-id="contractId"
            :dual-currency="false"
            :key="updateProformaInvoiceKey"
            @pdf-uploading="proformaInvoiceUploading"
            @pdf-uploaded="proformaInvoiceUploaded"
            @pdf-not-uploaded="proformaInvoiceNotUploaded"
            v-if="hasProformaInvoice"
        />
        <PrintContractProformaInvoiceBlobV3
            :contract-id="contractId"
            :dual-currency="true"
            :key="updateProformaInvoiceDualCurrencyKey"
            @pdf-uploading="proformaInvoiceDualCurrencyUploading"
            @pdf-uploaded="proformaInvoiceDualCurrencyUploaded"
            @pdf-not-uploaded="proformaInvoiceDualCurrencyNotUploaded"
            v-if="hasProformaInvoiceDualCurrency"
        />
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {log, makeId} from "Helpers/helpers";
import { api } from "Api"
import {v4 as uuidv4} from "uuid";
import {mapGetters} from "vuex";

const PrintContractProformaInvoiceBlobV3 = () => import("Views/v1/contracts/PrintContractProformaInvoiceBlobV3");
const PrintContractBlob = () => import('Views/v1/contracts/PrintContractBlob');

export default {
    name: "PrintContractButtons",
    props: ['contractId','updateMode','salesStatusId','contractNumber','salesTypeId','contractCurrencyId','officeCurrencyId'],
    components: {PrintContractProformaInvoiceBlobV3, PrintContractBlob },
    data() {
        return {
            contractCurrency: 'USD',
            currentContractPdf: null,
            currentProformaInvoicePdf: null,
            currentProformaInvoiceDualCurrencyPdf: null,
            hasContract: false,
            hasProformaInvoice: false,
            hasProformaInvoiceDualCurrency: false,
            loading: {
                contract: false,
                proformaInvoice: false,
                proformaInvoiceDualCurrency: false
            },
            officeCurrency: 'MYR',
            updateContractKey: 1,
            updateProformaInvoiceKey: 1,
            updateProformaInvoiceDualCurrencyKey: 1
        }
    },
    computed: {
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getExistingPdfs() {
            return new Promise((resolve, reject) => {
                api
                    .get("/contracts/" + this.contractId, {
                        params: {
                            fields: [
                                'contracts.location AS contracts__location',
                                'contracts.pi_location AS contracts__pi_location'
                            ]
                        }
                    })
                    .then(response => {
                        const contract = response.data.data[0]
                        if(contract){
                            this.currentContractPdf = contract.Contract.location
                            this.currentProformaInvoicePdf = contract.Contract.pi_location
                        }
                        resolve('success')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getExistingPdfs2() {
            return new Promise((resolve, reject) => {
                api
                    .get("/contracts/" + this.contractId, {
                        params: {
                            fields: [
                                'contracts.location AS contracts__location',
                                'contracts.pi_location AS contracts__pi_location',
                                'contracts.pi_dc_location AS contracts__pi_dc_location'
                            ]
                        }
                    })
                    .then(response => {
                        const contract = response.data.data[0]
                        if(contract){
                            this.currentContractPdf = contract.Contract.location
                            this.currentProformaInvoicePdf = contract.Contract.pi_location
                            this.currentProformaInvoiceDualCurrencyPdf = contract.Contract.pi_dc_location
                        }
                        resolve('success')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        contractNotUploaded() {
            this.loading.contract = false
            this.hasContract = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        contractUploaded(url) {
            this.currentContractPdf = url
            this.loading.contract = false
            this.viewFile(this.currentContractPdf)
            this.hasContract = false
        },
        contractUploading() {
            this.loading.contract = true
        },
        printContract() {
            this.loading.contract = true
            this.getExistingPdfs()
                .then(()=>{
                    if(this.currentContractPdf != null){
                        this.viewFile(this.currentContractPdf)
                            .then(() => this.loading.contract = false)
                            .catch(() => this.loading.contract = false)
                    } else {
                        this.hasContract = true
                    }
                })
                .catch((error) => {
                    log(error)
                    this.loading.contract = false
                })
        },
        printProformaInvoice() {
            this.loading.proformaInvoice = true
            this.getExistingPdfs()
                .then(()=>{
                    if(this.currentProformaInvoicePdf != null){
                        this.viewFile(this.currentProformaInvoicePdf)
                            .then(() => this.loading.proformaInvoice = false)
                            .catch(() => this.loading.proformaInvoice = false)
                    } else {
                        this.hasProformaInvoice = true
                    }
                })
                .catch((error) => {
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.proformaInvoice = false
                })
        },
        printProformaInvoiceDualCurrency() {
            this.loading.proformaInvoiceDualCurrency = true
            this.getExistingPdfs2()
                .then(()=>{
                    if(this.currentProformaInvoiceDualCurrencyPdf != null){
                        this.viewFile(this.currentProformaInvoiceDualCurrencyPdf)
                            .then(() => this.loading.proformaInvoiceDualCurrency = false)
                            .catch(() => this.loading.proformaInvoiceDualCurrency = false)
                    } else {
                        this.hasProformaInvoiceDualCurrency = true
                    }
                })
                .catch((error) => {
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.proformaInvoiceDualCurrency = false
                })
        },
        proformaInvoiceNotUploaded() {
            this.loading.proformaInvoice = false
            this.hasProformaInvoice = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        proformaInvoiceDualCurrencyNotUploaded() {
            this.loading.proformaInvoiceDualCurrency = false
            this.hasProformaInvoiceDualCurrency = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        proformaInvoiceUploaded(url) {
            this.currentProformaInvoicePdf = url
            this.loading.proformaInvoice = false
            this.viewFile(this.currentProformaInvoicePdf)
            this.hasProformaInvoice = false
        },
        proformaInvoiceDualCurrencyUploaded(url) {
            this.currentProformaInvoiceDualCurrencyPdf = url
            this.loading.proformaInvoiceDualCurrency = false
            this.viewFile(this.currentProformaInvoiceDualCurrencyPdf)
            this.hasProformaInvoiceDualCurrency = false
        },
        proformaInvoiceUploading() {
            this.loading.proformaInvoice = true
        },
        proformaInvoiceDualCurrencyUploading() {
            this.loading.proformaInvoiceDualCurrency = true
        },
        viewFile(file) {
            return new Promise((resolve,reject) => {
                try {
                    let tab = window.open(file + '?' + makeId(5), '_blank')
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                    resolve('done')
                } catch(error) {
                    reject('error')
                }
            })
        },
        viewPdf (type = null) {
            let document = encodeURIComponent(this.contractNumber) + (type == 'proforma' ? '_PI' : '')
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + (type == 'proforma' ? '/v1/contracts/proforma/print/' : '/v1/contracts/print/')
                + this.contractId
                + '/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank");
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    created(){
        const contractCurrency = this.allCurrencies.find(c => c.Currency.id == this.contractCurrencyId)
        const officeCurrency = this.allCurrencies.find(c => c.Currency.id == this.officeCurrencyId)
        if(contractCurrency) this.contractCurrency = contractCurrency.Currency.code
        if(officeCurrency) this.officeCurrency = officeCurrency.Currency.code
    }
}
</script>

<style scoped>

</style>